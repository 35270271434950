@mixin override {
    * {
      &:focus {
        outline: none;
      }
    }
  
    .lc-map {
      @include desktop {
        margin-left: 350px;
      }
      .lc-mapContainer {
        @include mobile {
          margin-top: 200px;
        }
        background: white !important;
      }
    }

    .lc-mapContainer {
      @include mobile {
        height: calc(var(--vh, 1vh) * 60 - 200px);
      }
    }
  
    .lc-board {
      width: 350px !important;
      font-size: 1em !important;
      margin: 0 !important;
      background: #f4f4f4;
      border-radius: 0;
      bottom: 0;
  
      @include desktop {
        max-height: unset;
      }
  
      @include mobile {
        width: unset !important;
        min-height: calc(40vh);
      }
  
      .lc-content {
        margin: 0 !important;
        border-radius: 0;
        box-shadow: initial !important;
        background: transparent !important;
        color: white !important;
      }
    }
  
    .lc-elevation {
      background: transparent !important;
      margin: 0;
    }
  
    .lc-board-header {
        display: none !important;
    }
  
    .lc-menu-item {
      background: transparent !important;
      border-color: $secondary !important;
      padding: 15px 0 !important;
      margin: 0 20px;
  
      &:last-child {
        border: initial !important;
      }
  
      &:hover {
        .lc-menu-title {
          color: $secondary !important;
        }
      }
  
      .lc-projects-link-selected {
        color: $secondary !important;
      }
    }
  
    .lc-menu-title {
      color: white !important;
      text-transform: uppercase !important;
      font-weight: initial !important;
      transition: all ease-in-out 250ms;
    }
  
    .lc-uimode-projects {
      border-color: $secondary !important;
      margin: 0 20px;
      padding: 15px 10px !important;
  
      .lc-uiline {
        font-size: initial !important;
      }
    }
  
    .lc-projects-bottom-text {
      font-weight: normal;
      margin: 30px 20px 0 20px;
    }

    .leaflet-control-attribution {
      pointer-events: none;
      cursor: default;
    }
    
    .leaflet-control-scale {
      display: none;
    }

    .lc-network-lines {
      color: #f4f4f4;
      margin: 20px;
      border-radius: 10px;
      border-top-left-radius: 0;
      border-bottom-right-radius: 10px;
    }

    .lc-lines-goto {
      height: 60px;
      width: 60px;
    }

    .lc-lines-goto-infobox {
      height: 30px;
      width: 30px;
    }

    .leaflet-popup-content {
      margin: 10px !important;
    }

    .leaflet-popup-content-wrapper {
      background-color: #f4f4f4;
    }

    .leaflet-popup-tip {
      background-color: #f4f4f4;
    }

    .lc-infobox-title {
      &.infobox-lines{
        padding: 0 0 10px 0 !important;
      }

      color: #6f6f6e !important;
      font-size: 1.2em;
      font-weight: 500;

      span {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .lc-infobox-content {
      border-radius: 10px;
      border-top-left-radius: 0;
      background-color: white;
    }

    hr {
      width: 90%;
      border: none;
      height: 1px;
      background: #6f6f6e;

      &:last-child {
        display: none;
      }
    }
  }
  